import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { readOnly } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import { cleanPrice } from 'crakn/utils/data-helpers';

export default class V2SelectionModel extends Model {
  @attr('price', { defaultValue: 0 }) adjustmentsTotal;
  @attr('price', { defaultValue: 0 }) balance;
  @attr('price') cashAdvanceTotal;
  @attr('price') creditRefundsTotal;
  @attr('price') discountsTotal;
  @attr('price') interestsTotal;
  @attr('price', { defaultValue: 0 }) localSalesTax;
  @attr('price') merchandiseTotal;
  @attr('price') nonTaxableTotal;
  @attr('price') nonTaxDiscountsTotal;
  @attr('price') overpaymentRefundsTotal;
  @attr('price') packageDiscount;
  @attr('price', { defaultValue: 0 }) paymentsTotal;
  @attr('price') refundsTotal;
  @attr('price', { defaultValue: 0 }) salesTax;
  @attr('price') serviceTotal;
  @attr('price') shippingTotal;
  @attr('price') specialChargeTotal;
  @attr('price', { defaultValue: 0 }) specialTax;
  @attr('price', { defaultValue: 0 }) stateSalesTax;
  @attr('price') subtotal;
  @attr('price') taxableTotal;
  @attr('price') taxDiscountsTotal;
  @attr('price') total;
  @attr('price', { defaultValue: 0 }) totalTax;

  @attr('moment-datetime') badDebtAt;
  @attr('string') coaIntegrationName;
  @attr('string') customId;
  @attr('boolean') isBadDebt;
  @attr('string') logoId;
  @attr('string') name;
  @attr('string') netsuiteId;
  @attr('string') purchaserRelationship;
  @attr('string') qbdId;
  @attr('string') qboId;
  @attr('string') reasonForUnposting;
  @attr('string') requiredPurchaseReason;
  @attr('string') selectionType;
  @attr('string') soaNote;
  @attr('string') uuid;

  @attr('moment-date') contractDate;
  @attr('moment-datetime') createdAt;
  @attr('moment-date') dueDate;
  @attr('moment-datetime') exportedAt;
  @attr('moment-datetime') postedAt;
  @attr('moment-datetime') sfgsLastGenerated;
  @attr('moment-datetime') statementLastGenerated;
  @attr('moment-datetime') unpostedAt;

  @attr('boolean', { defaultValue: false }) deletable;
  @attr('boolean') doNotExport;
  @attr('boolean') exported;
  @attr('boolean') hasUnexportedAccounting;
  @attr('boolean') isContract;
  @attr('boolean') inLockedPostingPeriod;
  @attr('boolean') posted;

  @attr() document;

  @belongsTo('v2/chartOfAccounts') chartOfAccounts;
  @belongsTo('v2/user', { async: true }) exportedBy;
  @belongsTo('v2/kase', { async: true }) kase;
  @belongsTo('v2/kase-financial', { async: true }) kaseFinancial;
  @belongsTo('v2/user', { async: true }) postedBy;
  @belongsTo('v2/person', { async: true }) purchaser;
  @belongsTo('v2/user', { async: true }) unpostedBy;
  @belongsTo('v2/contractTemplate') lastUsedContractTemplate;
  @belongsTo('v2/user', { async: true }) badDebtBy;

  //===== The following commented out relationships should be updated to use V2 models =====\\
  // @hasMany('adjustment', { async: true, inverse: 'selection' }) adjustments;
  // @hasMany('selectionCustomProduct', { inverse: 'selection' }) customProducts;
  // @hasMany('discount', { async: true, inverse: 'selection' }) discounts;
  @hasMany('v2/interests') interests;
  @hasMany('v2/selectionPackage', { inverse: 'selection' }) packages;
  // @hasMany('payment', { async: true, inverse: 'selection' }) payments;
  @hasMany('v2/selectionProduct', { inverse: 'selection' }) products;
  // @hasMany('refund', { async: true, inverse: 'selection' }) refunds;
  // @hasMany('selectionPackageProducts') selectionPackageProducts;

  @readOnly('kase.location.stateSalesTaxRate') salesTaxRate;

  @tracked selectionPurchaser = null;
  @tracked savedChanges = false;

  get isEmptySelection() {
    return this.products.length === 0 && this.packages.length === 0;
  }

  get totalLessDiscounts() {
    return cleanPrice(
      this.subtotal + this.salesTax + this.specialTax - this.discountsTotal
    );
  }

  get contractName() {
    if (this.isMiscSale) {
      return [this.purchaser.get('firstLastName'), '(Misc Sale)'].filter((i) => i).join(' ');
    } else if (
      this.kase &&
      (this.kase.get('deceased.firstName') ||
        this.kase.get('deceased.lastName'))
    ) {
      return this.kase.get('deceased.firstLastName');
    }

    return 'No Name';
  }

  get miscSalesUrl() {
    return `#/misc-sales/${this.id}/details`;
  }

  get isMiscSale() {
    return this.selectionType === 'misc_selection' || this.selectionType === 'MiscSelection' || this.selectionType === 'misc-selection'
  }

  get isKaseSelection() {
    return this.selectionType === 'kase_selection' || this.selectionType === 'KaseSelection' || this.selectionType === 'kase-selection'
  }

  get balanceColor() {
    return this.balance >= 0 ? 'green' : 'red';
  }
}
