import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class KasesEditTfeCustomUploadController extends Controller {
  @service api;
  @service flashes;
  @service intl;
  @service router;

  @tracked showValidations = false;

  @tracked esignEnabled = false;

  @action
  createCustomUpload(e) {
    e.preventDefault();
    e.target.disabled = true; // avoid double-click issues

    const isValid = this.validate();
    this.showValidations = !isValid;

    if (isValid) {
      this.postCustomUpload();
    } else {
      e.target.disabled = false; // avoid double-click issues
    }
  }

  validate() {
    let isValid = true;

    if (!this.model || !this.model.name) {
      isValid = false;
    } else if (!this.customUploadFile) {
      isValid = false;
    }
    return isValid;
  }

  async postCustomUpload() {
    const formData = new FormData();
    formData.append('document[name]', this.model.name);
    formData.append('document[esign_enabled]', this.esignEnabled || false);
    formData.append('documentFile', this.customUploadFile);
    formData.append('custom_upload', true);
    formData.append('kase_id', this.kase.id);

    const response = await this.api.post('tfe/documents', { body: formData });
    const json = await response.json();

    if (response.ok) {
      this.flashes.addSuccess(
        this.intl.t(
          'authenticated.kases.manage.edit.tfe.custom-upload.messages.success'
        )
      );
      this.router.transitionTo(
        'authenticated.kases.manage.edit.tfe.filled',
        this.kase.id
      );
    } else {
      let msg;
      if (json && json.errors) {
        msg = json.errors.join(', ');
      } else {
        msg = this.intl.t(
          'authenticated.kases.manage.edit.tfe.custom-upload.messages.error'
        );
      }

      this.flashes.addError(msg);
    }
  }
}
