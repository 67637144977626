import SearchRoute from 'crakn/routes/search';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AuthenticatedAdminFinancialConfigurationsEditBankAccountsRoute extends SearchRoute {
  @service router;
  @service session;
  model(params) {
    const coa = this.modelFor('authenticated.admin.financial-configurations.edit');
    return this.store.query('tribute-pay/v1/bank-account', { 
      chart_of_accounts_id: coa.get('uuid'),
      per_page: null,
      ...params 
    });
  }

  queryParams = {
    query: {
      refreshModel: true
    },
  };

  beforeModel() {
    if (
      !this.session.currentUser.isSuperuser &&
      !this.session.currentClient.can_view_financial_config_bank_accounts
    ) {
      this.router.transitionTo('authenticated.dashboard');
    }
  }

  @action
  refreshModel() {
    this.refresh();
  }
  
  setupController(controller,model) {
    super.setupController(...arguments);
    controller.isBankAccountsEmpty ??= model.length === 0;
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('query', '');
    }
  }
}

