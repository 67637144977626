import Component from '@glimmer/component';
import moment from 'moment';
import { action, set } from '@ember/object';

export default class PaymentByLocationReport extends Component {
  startsAt = moment().subtract(30, 'day').toISOString();
  endsAt = moment().toISOString();
  reportDate = 'collected_at';

  organization_location_id = null;

  get reportLink() {
    const params = new URLSearchParams({
      organization_location_id: this.organization_location_id,
      starts_at: this.startsAt,
      ends_at: this.endsAt,
      uuid: this.args.uuid,
      report_date: this.reportDate
    }).toString();

    return `/report/payments_by_location.xlsx?${params}`;
  }

  get fileUrl() {
    return `#/generated_document/${this.args.uuid}`;
  }

  @action
  generateReport() {
    this.args.onGenerateReport(this.reportLink);
  }

  @action
  setDate(attr, date) {
    date = moment(date).toISOString();
    set(this, attr, date);
  }

  @action
  setReportDate(value) {
    set(this, 'reportDate', value);
  }
}
