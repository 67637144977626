import Route from '@ember/routing/route'

export default class AdminLocationsEditTaxesRoute extends Route {
  model() {
    const model = this.modelFor('authenticated.admin.locations.edit');
    this.store.query('v2/salesTaxRate', { locationId: model.get('id') });

    return model;
  }
}
