import SearchRoute from 'crakn/routes/search';

export default class AuthenticatedCraknCheckTemplatesIndexRoute extends SearchRoute {
    modelType = 'document';

    resetController(controller, isExiting) {
        super.resetController(...arguments);

        if (isExiting) {
            controller.setProperties({
                query: '',
                page: 1
            });
        }
    }
}
