import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"popout-overlay {{if @isVisible \"is-visible\"}}\">\n</div>\n\n<div\n    class=\"popout-container {{if @isVisible \"is-visible\"}}\"\n    ...attributes>\n  <div class=\"popout-content\">\n    {{yield}}\n  </div>\n</div>\n", {"contents":"<div class=\"popout-overlay {{if @isVisible \"is-visible\"}}\">\n</div>\n\n<div\n    class=\"popout-container {{if @isVisible \"is-visible\"}}\"\n    ...attributes>\n  <div class=\"popout-content\">\n    {{yield}}\n  </div>\n</div>\n","moduleName":"crakn/components/g/crakn/popout/popout-container.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/popout/popout-container.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class PopoutContainerComponent extends Component {
  @tracked isVisible = false;

  @action
  open() {
    this.isVisible = true;
  }

  @action
  close() {
    this.isVisible = false;
    if (typeof this.args.onClose === 'function') {
      this.args.onClose();
    }
  }
}
