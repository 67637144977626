import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class KasesEditTfeFormsFilledRoute extends Route {
  @service session;
  @service router;
  @service api;

  layout = 'custom-aside';

  queryParams = {
    page: {
      refreshModel: true
    },
    archived: {
      refreshModel: true
    }
  };

  beforeModel() {
    if (!this.session.currentClient.can_view_form_in_tfe) {
      this.router.transitionTo('authenticated.kases.manage.edit.kase-forms');
    }
  }

  model(params) {
    const kase = this.modelFor('authenticated.kases.manage');

    return this.store.query('tfe/generatedDocument', {
      archived: params.archived || false,
      current_client: true,
      include: 'signers',
      kase_id: kase.id,
      page: params.page
    });
  }

  afterModel(model, transition) {
    // If there are no forms, redirect to the tfe/library, unless we're coming from the tfe/library
    if (model.get('length') === 0 && transition?.from?.name !== 'authenticated.kases.manage.edit.tfe.library') {
      this.router.transitionTo('authenticated.kases.manage.edit.tfe.library');
    }
  }

  async setupController(controller) {
    super.setupController(...arguments);

    const kase = this.modelFor('authenticated.kases.manage');

    controller.setProperties({
      kase,
      refillAllEnabled: true
    });
  }

  @action
  refreshModel() {
    this.refresh();
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.setProperties({
        model: null,
        kase: null,
        page: 1,
        refillAllEnabled: false,
        showVersionHistory: null
      });
    }
  }
}
