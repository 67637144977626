import Controller from '@ember/controller';
import { debounce } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action, computed, set } from '@ember/object';

export default class AdminUsersEditLocationAccessController extends Controller {
  @service store;
  @service flashes;
  @service intl;

  @tracked organization_id = null;
  @tracked location_name = '';
  @tracked userId;
  @tracked user;
  @tracked isPopoutVisible = false;

  @tracked unassignedLocations = [];

  queryParams = ['page', 'organization_id', 'location_name'];

  locationPage = 1;

  @tracked userLocations = [];

  @computed('userId')
  get v2Organizations() {
    return this.store.query('v2/organization', { user_id: this.userId });
  }

  @action
  removeUserLocation(userLocation) {
    userLocation.destroyRecord().then(() => {
      this.flashes.addSuccess(
        this.intl.t('authenticated.admin.users.edit.location-access.actions.remove.success')
      );
      this.send('refreshModel');
    })
    .catch(() => {
      this.flashes.addError(
        this.intl.t('authenticated.admin.users.edit.location-access.actions.remove.error')
      );
    });
  }

  @action
  setOrganizationId(value) {
    this.organization_id = isNaN(value) ? null : value;
  }

  @action
  setLocationName(value) {
    debounce(this, this._setLocationName, value, 400);
  }

  @action
  openPopout() {
    this.isPopoutVisible = true;
  }

  @action
  toggleLocation(locationId) {
    if (this.locationExists(locationId)) {
      set(this, 'userLocations', this.userLocations.filter((loc) => loc.id !== locationId));
    } else {
      set(this, 'userLocations', [...this.userLocations, this.unassignedLocations.find((loc) => loc.id === locationId)]);
    }
  }

  @action
  locationExists(locationId) {
    return this.userLocations.some((loc) => loc.id === locationId);
  }

  @action
  loadMoreLocations() {
    this.locationPage += 1;
    const page = this.locationPage;
    const userId = this.userId;

    this.store
      .query('v2/location', {
        per_page: 10,
        not_assigned_to_user_id: userId,
        archived: false,
        page
      })
      .then(results => {
        this.unassignedLocations.content.content.pushObjects(results.content);
      });
  }

  @action
  enableLocations() {
    this.userLocations.forEach((location) => {
      const existingUserLocation = this.store.query('v2/userLocation', {
        user_id: this.userId,
        location_id: location.id,
        archived: true
      })?.firstObject;

      if (existingUserLocation) {
        existingUserLocation.set('archived_at', null);
        existingUserLocation.save().then(() => {
          this.flashes.addSuccess(
            this.intl.t('authenticated.admin.users.edit.location-access.actions.access.success')
          );
          this.send('refreshModel');
          this.closePopout();
        })
        .catch(() => {
          this.flashes.addError(
            this.intl.t('authenticated.admin.users.edit.location-access.actions.access.error')
          );
        });
      } else {
        const userLocation = this.store.createRecord('v2/userLocation', {
          user: this.user,
          location
        });

        userLocation.save().then(() => {
          this.flashes.addSuccess(
            this.intl.t('authenticated.admin.users.edit.location-access.actions.access.success')
          );
          this.send('refreshModel');
          this.closePopout();
        })
        .catch(() => {
          this.flashes.addError(
            this.intl.t('authenticated.admin.users.edit.location-access.actions.access.error')
          );
        });
      }
    });
  }

  @action
  closePopout() {
    this.isPopoutVisible = false;
    set(this, 'userLocations', []);
  }

  _setLocationName(value) {
    this.location_name = value;
  }
}
