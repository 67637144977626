import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking'
import InquiryActions from 'crakn/mixins/inquiry-actions';

export default class ApplicationController extends Controller.extend(InquiryActions) {
  @service api;
  @service accessibleClients;
  @service browser;
  @service sidebar;
  @service session;
  @service router;
  @service intl;

  @tracked layout = 'layouts/default';
  @tracked isDropdownOpen = false;
  @tracked isClientsDropdownOpen = false;
  @tracked isModalVisible = false;
  @tracked isFullscreenActive = true;

  constructor(owner, args) {
    super(owner, args);

    this.accessibleClients.getRecentClients();
    if (this.session.currentClient) {
      const locale = this.getLocaleFromCountry(this.session.currentClient.operating_country);
      this.intl.setLocale([locale]);
    }
  }

  getLocaleFromCountry(country) {
    const countryToLocaleMap = {
      US: 'en-us',
      CA: 'en-ca'
    };

    return countryToLocaleMap[country] || 'en-us';
  }

  @action
  setupSidebar() {
    this.sidebar.setInitalState(this.browser.isDesktopWidth);
  }

  @action
  toggleSidebar() {
    this.sidebar.toggle();
  }

  @action
  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  @action
  toggleClientsDropdown() {
    this.isClientsDropdownOpen = !this.isClientsDropdownOpen;
  }

  @action
  closeModal() {
    this.isModalVisible = false;
  }

  @action
  openIntercom() {
    window.Intercom('show');
  }

  @action
  async switchCurrentClient(client) {
    const resp = await this.api.json.post('clients/switch', { body: { id: client.id } })

    if (resp.ok) {
      this.router.transitionTo('authenticated.dashboard').then(() => {
        window.location.reload(true)
      }).catch(() => {
        const transition = new Promise((resolve) => {
          window.location.assign('/#/dashboard');
          resolve()
        });
        transition.then(() => {
          window.location.reload(true)
        })
      });
    }
  }
}

