import Service, { inject as service } from '@ember/service';

export default class TaxVerbiageService extends Service {
  @service intl;

  get localSelectionSummaryPlaceholder() {
    return this.intl.t('services.tax-verbiage.selectionSummary.local');
  }

  get stateSelectionSummaryPlaceholder() {
    return this.intl.t('services.tax-verbiage.selectionSummary.state');
  }

  get totalSelectionSummaryPlaceholder() {
    return this.intl.t('services.tax-verbiage.selectionSummary.total');
  }

  get defaultSelectionSummaryPlaceholder() {
    return this.intl.t('services.tax-verbiage.selectionSummary.default');
  }

  get locationLocalTaxRatePlaceholder() {
    return this.intl.t('services.tax-verbiage.location.edit.taxes.placeholders.local');
  }

  get locationStateTaxRatePlaceholder() {
    return this.intl.t('services.tax-verbiage.location.edit.taxes.placeholders.state');
  }
}
