import { belongsTo, attr } from '@ember-data/model';
import { alias, or, notEmpty } from '@ember/object/computed';
import { validator, buildValidations } from 'ember-cp-validations';
import PersonModel from './person';
import { formatDateRange } from 'crakn/utils/date-helpers';
import { inject as service } from '@ember/service';

const Validations = buildValidations({
  firstName: validator('presence', true),
  lastName: validator('presence', true),
});

export default class DeceasedModel extends PersonModel.extend(Validations) {
  @service session;

  @attr('string') ancestry;
  @attr('string') placeOfDeathCity;
  @attr('string', { defaultValue: 'US' }) placeOfDeathCountry;
  @attr('string') placeOfDeathCounty;
  @attr('string') placeOfDeathLine1;
  @attr('string') placeOfDeathLine2;
  @attr('string') placeOfDeathName;
  @attr('string') placeOfDeathState;
  @attr('string') placeOfDeathTownship;
  @attr('string') placeOfDeathZipCode;
  @attr('string') profileDeathCircumstances;
  @attr('string') profileDeathTime;
  @attr('string') profileFoundTime;
  @attr('string') profileMannerOfDeath;
  @attr('string') profilePlaceOfDeathArea;
  @attr('string') profilePresumedDeathTime;

  @attr('boolean') profileBirthInfoIsComplete;
  @attr('boolean') profileContactInfoIsComplete;
  @attr('boolean') profileDeathInfoIsComplete;
  @attr('boolean') profileDeathInsideCityLimits;
  @attr('boolean') profileFetalDeath;
  @attr('boolean') profileFound;
  @attr('boolean') profileIsInfectiousDiseaseDeath;
  @attr('boolean') profilePersonalInfoIsComplete;

  @attr('number') kaseId;

  @attr('moment-datetime') profileDeathDate;

  @alias('profileDeathDate') deathDate;

  @belongsTo('kase', { async: true, inverse: 'deceased' }) kase;

  @or('birthDate', 'profileDeathDate') isBirthOrDeathPresent;

  @notEmpty(
    'placeOfDeathLine1',
    'placeOfDeathCity',
    'placeOfDeathState',
    'placeOfDeathZipCode',
    'placeOfDeathCountry'
  )
  placeOfDeathCheck;

  get birthDeathDates() {
    return formatDateRange(this.birthDate, this.profileDeathDate);
  }

  get ageAtDeath() {
    const birthDate = this.birthDate;
    const deathDate = this.profileDeathDate;

    if (
      birthDate &&
      deathDate &&
      birthDate.isValid() &&
      deathDate.isValid() &&
      birthDate.unix() <= deathDate.unix()
    ) {
      return deathDate.diff(birthDate, 'years');
    } else {
      return null;
    }
  }
}
