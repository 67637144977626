import Route from '@ember/routing/route';

export default class AuthenticatedCraknCheckTemplatesNewRoute extends Route {
    model() {
        return this.store.createRecord('tfe/document');
    }

    setupController(controller, model, _transition) {
        super.setupController(...arguments);
        controller.set('document', model);
    }

    resetController(controller, isExiting) {
        super.resetController(...arguments);
        if (isExiting) {
          controller.setProperties({
            document: null,
            documentFile: null,
            showValidation: false
          });
        }
    }
}
