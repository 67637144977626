import { ActiveModelSerializer } from 'active-model-adapter';

export default class TributePayV1BankAccountSerializer extends ActiveModelSerializer {
    modelNameFromPayloadKey(key) {
        if(key == 'bank_account') {
            return 'tribute-pay/v1/bank-account';
        }

        return super.modelNameFromPayloadKey(...arguments);
      }

    payloadKeyFromModelName(modelName) {
        if (modelName === 'tribute-pay/v1/bank-account') {
          return 'bank_account'; 
        }
        
        return super.payloadKeyFromModelName(...arguments);
      }
      
    attrs = {
        bankAccountStaffAssignments: { serialize: false, deserialize: 'records' }
    };
}
